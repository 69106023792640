import React from "react"

import { Login, Layout, SEO, Container } from "src/sws-ui"

const LoginPage = () => (
  <Layout>
    <SEO title="Login" />
    <div className={"container"}>
      <Login />
    </div>
  </Layout>
)

export default LoginPage
